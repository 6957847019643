import React from 'react';
import FileChooseButton from "../components/FileChooseButton";
import AppContext from "../contexts/AppContext";
import {createMd5Token} from "../utils/text";

export default class IndexPage extends React.Component {

  state = {
    images: [],
  };

  componentDidMount() {
    this.context.hideLoader();
  }

  handleFileSelected = (images) => {
    const files = [];

    Array.prototype.forEach.call(images, (image) => {
      const file = {};

      if (image instanceof File) {
        file.src = URL.createObjectURL(image);
      } else {
        file.src = image;
      }

      file.id = createMd5Token(Date.now(), file.src);

      files.push(file);
    });

    console.log(images);

    this.setState({
      images: [...this.state.images, ...files]
    });
  };

  handleProcessClick = () => {
    // todo
  };

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((item) => item.id === image.id);
    if (pos > -1) {
      this.setState({
        images: [
          ...this.state.images.slice(0, pos),
          ...this.state.images.slice(pos + 1),
        ],
      });
    }
  };

  render() {
    return <div>

      <FileChooseButton
        className="btn-upload-foto"
        hidden={this.state.images.length >= 20}
        onFileSelected={this.handleFileSelected}
        amount={10}>
        Add photos
      </FileChooseButton>

      <button onClick={this.handleProcessClick} hidden={this.state.images.length <= 10}>
        Process
      </button>

      <hr />

      <div className="images-grid">
        {this.state.images.map((image) => <div
          key={image.id}
          className="image-item"
          onClick={() => this.handleRemoveImage(image)}>
          <img src={image.src} alt="" />
        </div>)}
      </div>

    </div>;
  }
}

IndexPage.contextType = AppContext;