import {getLocationQueryObject} from "./utils/text";
import clientStorage from "./utils/client-storage";
import {detect} from "detect-browser";
import {generateHash} from "./utils/text";
import {androidRequestParams, iosRequestParams} from "./utils/constants";
import {
  isWebviewBuild,
  isWebviewCountriesOf,
  isWebviewCountryOf
} from "./utils/config.utils";
import {supportedLanguagePaths} from "./routes";

if (!clientStorage.hasFirstVisitAt()) {
  clientStorage.setFirstVisitAt(Date.now());
}

let query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android", "android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
let isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";
let isWebviewMock = false;

if (isWebview) {
  clientStorage.setWebviewRequestParams(query);
}

const webviewHostNames = (process.env.REACT_APP_WEBVIEW_HOSTNAMES || "").split(",");

if (!isWebview && webviewHostNames.includes(window.location.hostname)) {
  let requestParams = clientStorage.getWebviewRequestParams();

  isWebview = true;
  isWebviewMock = true;

  if (Object.keys(requestParams).length === 0) {
    if (["ios"].indexOf(platformInfo.os.toLowerCase()) > -1) {
      requestParams = iosRequestParams;
      requestParams.vicman_unified_id = generateHash();
    } else {
      requestParams = androidRequestParams;
      requestParams.aid = generateHash();
    }

    clientStorage.setWebviewRequestParams(requestParams);
  }

  query = {...requestParams, ...query};
}

window.clientConfig = {
  platform: platformInfo,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebMobileDesktopMode: false,
  isWebMobileAndroid: platformInfo.os.toLowerCase() === "android" || platformInfo.os.toLowerCase() === "android os",
  isWebMobileIOS: platformInfo.os.toLowerCase() === "ios",
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  isWebviewMock: isWebviewMock,
  isAnyMobile: isWebview || platformIsMobile,
  webviewParams: isWebview ? query : {},
  isPro: query["is_pro"] === "1",
  isTrial: query["subs_is_trial"] === "1",
  isCancelledTrial: query["subs_is_trial"] === "1" && query["subs_state"] === "cancelled",
  subscriptionSku: query["subs_id"],
  locale: query["locale"] || query["lang"] || clientStorage.getSelectedLang() || window.navigator.language || undefined,
  splitGroupId: undefined,
  loadedUrlParams: query,
  features: {},
  isNew: Date.now() < (clientStorage.getFirstVisitAt() + 43_200_000), // 12h
  geoipCountryCode: null,
};

window.clientConfig.isUsaUser = isWebviewCountryOf("us", true);
window.clientConfig.isBrazilianUser = isWebviewCountryOf("br");
window.clientConfig.isTurkeyUser = isWebviewCountryOf("tr");
window.clientConfig.isIndianUser = isWebviewCountryOf("in");
window.clientConfig.isIndiaRegionUser = isWebviewCountriesOf(["in", "pk", "bd"]);

if (window.clientConfig.isPro && window.clientConfig.isCancelledTrial && window.clientConfig.isWebviewIOS) {
  window.clientConfig.isPro = false;
}

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

window.clientConfig.isWebMobileDesktopMode = (function(){
  const webkitVer = parseInt(/WebKit\/(\d+)|$/.exec(window.navigator.appVersion || "")[1], 10); // also matches AppleWebKit
  const isGoogle = webkitVer && window.navigator.vendor.indexOf("Google") === 0;  // Also true for Opera Mobile and maybe others
  const isAndroid = isGoogle && window.navigator.userAgent.indexOf("Android") > 0;  // Careful - Firefox and Windows Mobile also have Android in user agent
  return !isAndroid
    && isGoogle
    && ((window.navigator.platform || "").indexOf('Linux a') === 0)
    && "ontouchstart" in window.document.documentElement;
})();

if (query["mock_mobile_desktop_mode"] === "1") {
  window.clientConfig.isWebMobileDesktopMode = true;
}

// ---

// if (window.clientConfig.isWebview) {
//   window.clientConfig.isNew = parseInt(window.clientConfig.webviewParams["session_idx"] || 1) <= 1;
// }

window.clientConfig.isOld = !window.clientConfig.isNew;

// ---

if (window.clientConfig.isWebview) {
  window.clientConfig.token = window.clientConfig.isWebviewAndroid
    ? `w:a:${query["aid"]}`
    : `w:i:${query["vicman_unified_id"]}`;
} else {
  const clientToken = clientStorage.getClientToken();
  if (clientToken) {
    window.clientConfig.token = clientToken;
  } else {
    const type = platformIsMobile ? "m" : "d";
    const hash = generateHash();

    window.clientConfig.token = `b:${type}:${hash}`;
    clientStorage.setClientToken(window.clientConfig.token);
  }
}

// ---

if (window.clientConfig.isWebview) {
  const aid = query["aid"] || query["vicman_unified_id"];
  const num = parseInt(aid.substring(aid.length - 2), 16);
  const group = Math.floor(num / (255 / 10)) + 1;

  window.clientConfig.splitGroupId = Math.min(group, 10); // fix for ff => 11 group
} else {
  const groupId = query["split_group"] || clientStorage.getSplitGroupId();
  if (groupId) {
    window.clientConfig.splitGroupId = parseInt(groupId);
  } else {
    window.clientConfig.splitGroupId = Math.floor(Math.random() * 10) + 1;
  }

  clientStorage.setSplitGroupId(window.clientConfig.splitGroupId);
}

// ---

window.clientConfig.features.isAutoStartCreatives = window.clientConfig.splitGroupId < 11; // true
window.clientConfig.features.tabsWithRandomOrder = true;
window.clientConfig.features.showResubscribeView = !window.clientConfig.isPro
  && window.clientConfig.isCancelledTrial
  && [6, 7, 8, 9, 10].includes(window.clientConfig.splitGroupId);

window.clientConfig.features.fullSizeIsAvailable = isWebviewBuild(70, 146);

const androidPreResultIgnoredCountries = [
  'IR', 'IN', 'RU', 'DZ', 'IQ', 'BD', 'LK', 'NP', 'MM', 'BN', 'BT',
  'AZ', 'KG', 'UZ', 'GE', 'AM', 'TJ', 'TM', 'NG', 'GH', 'RE', 'CI',
  'CM', 'TZ', 'SN', 'ZW', 'AO', 'UG', 'ZM', 'MG', 'NA', 'GA', 'MZ',
  'CD', 'SD', 'ET', 'BW', 'TG', 'BJ', 'GN', 'ML', 'RW', 'BF', 'SO',
  'LR', 'MW', 'CG', 'CV', 'MR', 'DJ', 'NE', 'SL', 'SZ', 'GQ', 'BI',
  'GM', 'LS', 'TD', 'CF', 'SS', 'GW', 'ER',
];

window.clientConfig.features.showFullSizeBannerDisabledByRegion =
  (window.clientConfig.isWebviewIOS && isWebviewCountryOf("IR", true))
  || (window.clientConfig.isWebviewAndroid && isWebviewCountriesOf(androidPreResultIgnoredCountries, true))

window.clientConfig.features.showFullSizeBanner = isWebviewBuild(87, 241)
  && !window.clientConfig.isPro
  && !window.clientConfig.isTurkeyUser
;

// ---

window.appConfig = {

  project: {
    name: process.env.REACT_APP_PROJECT_NAME || "",
    key: process.env.REACT_APP_PROJECT_KEY || "",
  },

  isTestInstance: process.env.REACT_APP_TEST_INSTANCE === "true",
  isProdMode: process.env.NODE_ENV.indexOf("prod") === 0,
  isDebug: process.env.NODE_ENV.indexOf("prod") !== 0
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  build: process.env.REACT_APP_BUILD_INFO || {},

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    pwAssets: process.env.REACT_APP_PW_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
    apiSign: process.env.REACT_APP_API_SIGN_PATH,
    proxy: process.env.REACT_APP_PW_PROXY_PATH,
    stat: process.env.REACT_APP_STAT_PATH,
    tempImagesUploadEndpoint: process.env.REACT_APP_TEMP_IMAGES_UPLOAD_ENDPOINT,
  },

  photolab: {
    appId: process.env.REACT_APP_PHOTOLAB_APP_ID,
    path: process.env.REACT_APP_PHOTOLAB_API_PATH,
    signEndpoint: process.env.REACT_APP_PHOTOLAB_SIGN_ENDPOINT,
    signWithAddTaskEndpoint: process.env.REACT_APP_PHOTOLAB_SIGN_WITH_ADDTASK_ENDPOINT,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
    overloadModeIsEnabled: process.env.REACT_APP_ANALYTICS_OVERLOAD_MODE_IS_ENABLED === "true",
    overloadModeEvents: [
      "change_mask_click",
      "component_error",
      "creative_failed",
      "creative_view",
      "download",
      "frontend_watermark_failed",
      "full_size_results_click",
      "get_app_click",
      "lcp_report",
      "page",
      "photo_select",
      "tab_refresh",
      "tab_select",
    ],
  },

  processingTimings: {
    isEnabled: process.env.REACT_APP_PROCESSING_TIMINGS_ENABLED === "true",
    endpoint: process.env.REACT_APP_PROCESSING_TIMINGS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
    allowedUserGroups: envAsArrayOfNumbers(process.env.REACT_APP_HITS_ALLOWED_USER_GROUPS || ""),
  },

  sentry: {
    isEnabled: envAsBoolean(process.env.REACT_APP_SENTRY_ENABLED),
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
    sampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0),
  },

  googleAnalytics: {
    isEnabled: (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") && window.clientConfig.isWeb,
    trackerId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },

  nativeAds: {
    isEnabled: process.env.REACT_APP_NATIVE_ADS_IS_ENABLED === "true",
  },

  maskEditor: {
    isEnabledInWeb: process.env.REACT_APP_MASK_EDITOR_ENABLED_WEB === "true",
    isEnabledInWebview: process.env.REACT_APP_MASK_EDITOR_ENABLED_WEBVIEW === "true",
  },

  webview: {
    rateAppDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_RATEAPP_DIALOG_PERCENT),
  },

  processings: {
    timeout: parseInt(process.env.REACT_APP_PROCESSINGS_TIMEOUT),
    creativeTimeout: parseInt(process.env.REACT_APP_PROCESSINGS_CREATIVE_TIMEOUT),
    creativeStatusNewDuration: parseInt(process.env.REACT_APP_CREATIVE_STATUS_NEW_DURATION) || 0,
    errorButtonTimeout: parseInt(process.env.REACT_APP_PROCESSINGS_ERROR_BUTTON_TIMEOUT),

    creativesRollout: {
      at: envAsDateFromDateString(process.env.REACT_APP_PROCESSINGS_CREATIVES_ROLLOUT_AT) || 0,
      heatingOffset: parseInt(process.env.REACT_APP_PROCESSINGS_CREATIVES_ROLLOUT_HEATING_OFFSET) || 0,
    },

    config: {
      rolloutIsEnabled: envAsBoolean(process.env.REACT_APP_PROCESSINGS_CONFIG_ROLLOUT_ENABLED),
      rolloutInterval: parseInt(process.env.REACT_APP_PROCESSINGS_CONFIG_ROLLOUT_INTERVAL) * 60_000,
      useOldOnUserGroups: envAsArrayOfNumbers(process.env.REACT_APP_PROCESSINGS_CONFIG_OLD_CONFIG_USERGROUPS || ""),
    },

    mobileDesktopModePhotosLimit: parseInt(process.env.REACT_APP_PROCESSINGS_MOBILE_DESKTOP_MODE_PHOTOS_LIMIT || "0"),
    mobilePhotosLimit: parseInt(process.env.REACT_APP_PROCESSINGS_MOBILE_PHOTOS_LIMIT || "0"),
  },
};

function envAsBoolean(input) {
  return input === "true";
}

function envAsArrayOfNumbers(input, delimiter = ",") {
  return input
    .split(delimiter)
    .map((item) => parseInt(item))
    .filter((item) => !isNaN(item));
}

function envAsDateFromDateString(input) {
  return new Date(input);
}
