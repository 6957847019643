module.exports = {

    "try_another_photo": "Change photo",

    "time_next_try": "⏰ Try again in {{time}}",

    // "safe_and_cool": "Help us <span>fight fake news!</span>",
    // "safe_and_cool_2": "CIA, FSS, AI - it’s all about us 🤫",
    // "safe_and_cool_3": "The flip side of being #1 app 👻",
    // "safe_and_cool_4": "Proving the rain isn’t dry.&nbsp;☔️ Wanna&nbsp;join?",

    "refine_the_selection": "Refine the selection",

    "logo": "#newprofilepic",
    "landing__title_1": "Your amazing profile picture maker.",
    "landing__title_2": "Fresh arrivals weekly. Totally AI-driven.",
    "landing__subtitle": "The world around is ever changing and your online image should do too!",
    "landing__get_the_app_button": "Get the app",

    "choose_photo": "Choose photo",

    // "choose_hide_text": "hide caption",
    // "choose_show_text": "show caption",

    "modal__title": "Great choice!",
    "modal__subtitle": "Now it's time to update your profile pic and make your pals and followers go jealous ;)",

    // "btn_creative_refresh": "+{{num}} more",

    "store_app": "app store",
    "store_google": "google play",

    "main_info_title": "100К+ people liked it / 100K+ likes",
    "main_info_text": "Amaze your friends and followers with fresh-looking avatars that reflect your current mood.",

    "rules_list_title": "The rules are simple:",
    "rules_list_item1": "Select the profile picture you like most in your current state of mind",
    "rules_list_item2": "and <span>return a week later</span> for new effects, new options, and new picture.",

    "processing_tip": "Tip {{item}}/{{total}}",
    "processing_text_1": "Why stick to one profile pic? Let it change with you! 🙈",
    "processing_text_2": "Break the rules and update photo as often as you want! 😎",
    "processing_text_3": "Choose the artwork that reflects your current mood! 🤩",
    "processing_text_4": "Surprise your friends with new beautiful profile every time 👍",
    "processing_text_5": "Come back in a couple of days for new ideas 🙃",

    // "processing2_0": "Break the rules! Do it your way:",
    // "processing2_1": "Update your profile picture once in a couple of days 🔄",
    // "processing2_2": "See increase in attention to your profile as you do it 😎",
    // "processing2_3": "Return for new effects and designs 🤩",
    // "processing2_4": "Repeat 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Oops, we’ve got a situation…",
    "resubscribe_text3": "You’ve stopped your subscription and now all Premium features are locked.<br />Resubscribe to fix it.",
    "resubscribe_button": "Resubscribe",
    "resubscribe_processing_text": "Processing...",

    "maskeditor__title": "Refine the selection",
    "maskeditor__subtitle": "Scroll to zoom, drag with mouse middle button",

    "btn_undo": "undo",
    "btn_redo": "redo",
    "btn_save": "save",
    "size": "size",
    "btn_get": "GET FREE APP",

    "result__creative_download": "Download",
    "result__creative_download_webview": "Save & Share",
    "result__creative_retry": "Retry",
    "result__creative_failed_message": "Oops, something went wrong with this style. 😔 Choose any other style or try again.",
    "result__creative_saved_to_gallery": "Saved to gallery",
    // "result__choose_title": "Can't choose?",
    // "result__choose_text": "Ask your followers to help by posting collage with different options",

    "rateapp_dialog__title": "Love <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Give stars to the app & help other users discover and love it too!",
    "rateapp_dialog__rate_button": "Rate!",
    "rateapp_dialog__skip_button": "Skip",

    "continue_with_app__title": "Love <span>#NewProfilePic</span>?",
    "continue_with_app__message": "To keep creating profile pics please get the app",
    "continue_with_app__continue_button": "Get the app",
    "continue_with_app__dismiss_button": "Skip",

    // "ad_tab_title": "Find even more great stuff in our <span>Photo Lab</span> app",

    "error__error": "Error",
    "modal_button": "GOT IT",

    "label_new": "new",
    "label_pro": "pro",

    "remove_logo_modal__title": "To keep or not to keep… 🤔",
    "remove_logo_modal__message": "Hey, did you know that the logo is a part of this effect’s design? It’ll make us happy if you choose to keep it.",
    "remove_logo_modal__btn_cancel": "Keep the logo",
    "remove_logo_modal__btn_remove": "Remove anyway",

    "toggle_watermark_tooltip__text": "Tap the logo to remove it",
    "toggle_watermark_on": "Logo on",
    "toggle_watermark_off": "Logo off",

    "internal_error": "An error occurred...",
    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "error_overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

    // "timer__title": "New styles in:",
    // "timer__expired_text_landing": "Choose photo to try new styles",
    // "timer__expired_text_result": "Choose photo to try new styles:",

    // "timer__days_one": "day",
    // "timer__days_other": "days",
    // "timer__hours_one": "hour",
    // "timer__hours_other": "hours",
    // "timer__minutes_one": "minute",
    // "timer__minutes_other": "minutes",
    // "timer__seconds_one": "second",
    // "timer__seconds_other": "seconds",

    "contact_us": "Contact Us",
    "privacy_policy": "Privacy Policy",
    "terms": "Terms",

    // "btn_full_size_results_v1": "New! <span>Full-size results</span>",
    "btn_full_size_results_v2": "New! Full-size results",

    // "full_size_promotion__download_full_size": "GET ACCESS",
    //
    // "full_size_promotion__show_more_1": "Let's show more of you<br />with our full-size results",
    // "full_size_promotion__show_more_2": "This is just one of our<br />stunning full-size results",
    // "full_size_promotion__show_more_3": "Fancy <span>full-size</span> results like that?<br /><span>We can do this too!</span>",

    // "full_size_promotion__only_pro_1": "For Premium users only",
    // "full_size_promotion__only_pro_2": "Goes with Premium plan",

    // "full_size_promotion__skip_1": "Skip for now and proceed",
    // "full_size_promotion__skip_2": "I just want my profile pic",
    // "full_size_promotion__skip_3": "Not now",

    // "full_size_results_label": "New! Full-size results",

};

