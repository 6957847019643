import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import IndexPage from "./pages/IndexPage";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

class App extends React.Component {

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.classList.add("ui--creative-tabs-v2");

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  render() {
    return (
      <div>
        <WindowSizeWatcher />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          <BrowserRouter>
            <Switch>
              <Route exact path={routes.INDEX} render={(props) => <IndexPage {...props} />} />
            </Switch>
          </BrowserRouter>
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<AppContextProvider><App /></AppContextProvider>);